import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';

export const rootRouterConfig: Routes = [
  {
    path: '',
        redirectTo: 'sessions/signin',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session'}
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
    
        {
            path: 'master',
            loadChildren: () => import('./appviews/Master/masters.module').then(m => m.MastersModule),
            data: { title: 'Masters', breadcrumb: 'Masters' }
        },
        {
            path: 'web-tools',
            loadChildren: () => import('./appviews/web-tools/web-tools.module').then(m => m.WebToolsModule),
            data: { title: 'Web_Tools', breadcrumb: 'Web_Tools' }
        },
        {
            path: 'agent',
            loadChildren: () => import('./appviews/agent/agent.module').then(m => m.AgentModule),
            data: { title: 'Agents', breadcrumb: 'Agents' }
        },
        {
            path: 'visa',
            loadChildren: () => import('./appviews/visa/visa.module').then(m => m.VisaModule),
            data: { title: 'Visa', breadcrumb: 'Visa' }
        },
        {
            path: 'order',
            loadChildren: () => import('./appviews/orders/order.module').then(m => m.OrderModule),
            data: { title: 'Orders', breadcrumb: 'Orders' }
        },
        {
            path: 'manage',
            loadChildren: () => import('./appviews/manage/manage.module').then(m => m.ManageModule),
            data: { title: 'Management', breadcrumb: 'Management' }
        },
        {
            path: 'agent-cp',
            loadChildren: () => import('./appviews-agent/agent-cp.module').then(m => m.AgentCPModule),
            data: { title: 'Agent_CP', breadcrumb: 'Agent_CP' }
        },
        {
            path: 'reports',
            loadChildren: () => import('./appviews/reports/reports.module').then(m => m.ReportsModule),
            data: { title: 'Reports', breadcrumb: 'Reports' }
        },
        {
            path: 'help-desk',
            loadChildren: () => import('./appviews/help-desk/help-desk.module').then(m => m.HelpDeskModule),
            data: { title: 'Help_Desk', breadcrumb: 'Help_Desk' }
        }
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

