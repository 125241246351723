export const config = {



        apiUrl: 'https://visacp.hekaya.co/api/',
        apiUrl2: 'https://visacp.hekaya.co/',



    apiUrlPay: 'https://pay.hekaya.co/api/WebTools/Payment/paymentRequest/',
    authRoles: {
        sa: ['SA'], // Only Super Admin has access
        admin: ['SA', 'Admin'], // Only SA & Admin has access
        editor: ['SA', 'Admin', 'Editor'], // Only SA & Admin & Editor has access
        user: ['SA', 'Admin', 'Editor', 'User'], // Only SA & Admin & Editor & User has access
        guest: ['SA', 'Admin', 'Editor', 'User', 'Guest'] // Everyone has access
    }
}

//    apiUrl: 'http://localhost:7272/api/',
//    apiUrl2: 'http://localhost:7272/',


//    apiUrl: 'https://visacp.hekaya.co/api/',
//    apiUrl2: 'https://visacp.hekaya.co/',




    //apiUrlPay: 'https://demopay.hekaya.co/Payment/CPA.aspx',
    //apiUrlPay: 'https://pay.hekaya.co/Payment/CPA.aspx',
    //apiUrlPay: 'https://pay.hekaya.co/api/WebTools/Payment/paymentRequest/',